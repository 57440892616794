<template>
  <component :is="layout"> <router-view /> </component>
</template>

<script setup>
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import { markRaw, ref } from "vue";

const layout = ref(null);
layout.value = markRaw(DefaultLayout);
</script>
