<template>
  <div class="home">
    
    <!-- Home SLIDER -->
    <div class="home-slider">
      <div class="home-slide">
        <div class="home-slide__info">
          <div class="home-slide__icon">
            <img src="@/assets/images/main/chief.svg" alt="icon">
          </div>
          <div class="home-slide__title">Готовь, как шеф!</div>
          <div class="home-slide__subtitle">Простые и вкусные рецепты с пошаговым приготовлением</div>
        </div>
        <div class="home-slide__img">
          <img src="@/assets/images/main/vegatables.png" alt="slide">
        </div>
      </div>
      <div class="home-slide__navigation">
        <button class="home-slide__prev">
          <img src="@/assets/icons/left.svg" alt="icon">
        </button>
        <button class="home-slide__next">
          <img src="@/assets/icons/right.svg" alt="icon">
        </button>
      </div>
    </div>

    <!-- Home PRODUCTS -->
    <div class="home__products">

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Мясо и курица</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/meat.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card added-favorites">
            <div class="product-card__img">
              <img src="@/assets/images/products/meat.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/meat.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/meat.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Рыба и морепродукты</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/fish.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card added-favorites">
            <div class="product-card__img">
              <img src="@/assets/images/products/fish.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/fish.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/fish.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Готовь, как шеф!</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/chicken.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Молочная продукция</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/milk.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/milk.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/milk.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/milk.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Полуфабрикаты и маринады</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/semi-finished.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/semi-finished.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/semi-finished.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/semi-finished.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Напитки и вода</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/beverages.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/beverages.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/beverages.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/beverages.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Бакалея</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/grocery.png" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/grocery.png" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/grocery.png" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/grocery.png" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Хлеб и выпечка</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/bread.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/bread.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/bread.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/bread.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Яйца</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/eggs.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Фаст фуд и снеки</h2>
          <a href="#">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/fast-food.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/fast-food.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/fast-food.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/fast-food.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

      <!-- Home PRODUCT -->
      <div class="home__product">

        <div class="home__product-header">
          <h2 class="title">Пакет бумажный</h2>
          <a href="#" class="is-disabled">Смотреть все</a>
          <button>
            <svg width="7" height="15" viewBox="0 0 7 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.28292 13.1053C0.118644 13.3195 0.0273795 13.6015 0.0365062 13.8947C0.0365062 14.188 0.12777 14.4586 0.301173 14.6729C0.46545 14.8759 0.693612 15 0.9309 15C1.16819 15 1.39635 14.8985 1.56975 14.6955L6.73533 8.31203C6.81747 8.21053 6.88136 8.08647 6.92699 7.95113C6.97262 7.81579 7 7.66917 7 7.52256C7 7.37594 6.97262 7.22932 6.92699 7.08271C6.88136 6.94737 6.81747 6.82331 6.73533 6.7218L1.56975 0.338346C1.48761 0.236842 1.38722 0.146617 1.27771 0.0902256C1.15906 0.0338346 1.04042 0 0.921773 0C0.803129 0 0.675358 0.0225564 0.565841 0.0789474C0.456323 0.135338 0.355932 0.225564 0.264668 0.327068C0.182529 0.428571 0.118644 0.56391 0.0730119 0.699248C0.0182528 0.834586 0 0.981203 0 1.1391C0 1.28571 0.0273795 1.43233 0.0730119 1.56767C0.118644 1.70301 0.191656 1.82707 0.28292 1.93985L4.80052 7.52256L3.94263 8.57143L0.28292 13.1053Z" fill="#2D2F32"/>
            </svg>
          </button>
        </div>

        <div class="product-cards">

          <!-- product CARD -->
          <div class="product-card">
            <div class="product-card__img">
              <img src="@/assets/images/products/meat.jpg" alt="product image">
              <button class="add-favorites-btn">
                <svg width="23" height="28" viewBox="0 0 23 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.795 2.78105C2.28597 3.28115 2 3.95942 2 4.66667V26L11.5 21.3333L21 26V4.66667C21 3.95942 20.714 3.28115 20.205 2.78105C19.696 2.28095 19.0056 2 18.2857 2H4.71429C3.99441 2 3.30402 2.28095 2.795 2.78105Z" stroke="#fff" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </button>
              <div class="product-wight">1 кг</div>
            </div>
            <div class="product-card__name">Оленина отборная, кг</div>
            <div class="product-card__footer">
              <div class="product-card__price">5 500 тг</div>
              <button class="product-card__btn">
                <span class="product-card__btn-name">В корзину</span>
                <div class="product-card__btn-price">
                  <div class="product-card__btn-minus">
                    <svg width="14" height="4" viewBox="0 0 14 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 2H14" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                  <div class="product-card__btn-price-amount">5 500 тг</div>
                  <div class="product-card__btn-plus">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 7H14M7 14V0" stroke="white" stroke-width="2.8"/>
                    </svg>
                  </div>
                </div>
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
