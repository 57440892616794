<template>
  <div class="default-layout">
    <app-header />
    <div class="container">
      <slot></slot>
    </div>
    <app-footer />
    <button class="pageup">
      <svg
        width="63"
        height="63"
        viewBox="0 0 63 63"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="31.5"
          cy="31.5"
          r="31.5"
          transform="rotate(-90 31.5 31.5)"
          fill="white"
        />
        <path
          d="M42.4632 37.4746C42.8632 37.7797 43.3895 37.9492 43.9368 37.9322C44.4842 37.9322 44.9895 37.7627 45.3895 37.4407C45.7684 37.1356 46 36.7119 46 36.2712C46 35.8305 45.8105 35.4068 45.4316 35.0847L33.5158 25.4915C33.3263 25.339 33.0947 25.2203 32.8421 25.1356C32.5895 25.0508 32.3158 25 32.0421 25C31.7684 25 31.4947 25.0508 31.2211 25.1356C30.9684 25.2203 30.7368 25.339 30.5474 25.4915L18.6316 35.0847C18.4421 35.2373 18.2737 35.4237 18.1684 35.6271C18.0632 35.8475 18 36.0678 18 36.2881C18 36.5085 18.0421 36.7458 18.1474 36.9492C18.2526 37.1525 18.4211 37.339 18.6105 37.5085C18.8 37.661 19.0526 37.7797 19.3053 37.8644C19.5579 37.9661 19.8316 38 20.1263 38C20.4 38 20.6737 37.9492 20.9263 37.8644C21.1789 37.7797 21.4105 37.6441 21.6211 37.4746L32.0421 29.0847L34 30.678L42.4632 37.4746Z"
          fill="#0B750B"
        />
      </svg>
    </button>
  </div>
</template>

<script setup name="DefaultLayout">
import { defineAsyncComponent } from "@vue/runtime-core";

const AppHeader = defineAsyncComponent(() =>
  import("@/components/shared/AppHeader")
);
const AppFooter = defineAsyncComponent(() =>
  import("@/components/shared/AppFooter")
);
</script>
